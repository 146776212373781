<template>
  <div class="">
    SubCategory
  </div>
</template>

<script>
export default {
  name:'SubCategory',
  setup() {
  }
}
</script>

<style lang="less" scoped>

</style>
